import axios, { AxiosInstance, AxiosResponse } from "axios";
// import { response } from "express";
import {
  cityListResponse,
  hotspotAreaResponse,
  hotspotListResponse,
  MapOnRiderResponseHyperTrack,
  merchantListResponse,
  riderListSummaryResponse,
  workHomeLocationResponse,
  allhotspotListResponse,
  eautohotspotListResponse,
  threewheelerSummaryResponse,
  threewheelerTabledata,
  IotNumber,
  HubWiseData,
  IotType,
  TrackerDetail,
  VehicleStatusWise,
  MerchantCategory,
  CityData,
  FleetTypeData,
  LastLocationYear,
  LastLocationMonth,
  LastLocationVsStartTime,
  IotDisconnections,
  MapIotLocation,
  MobileIotCount,
  MobileFleetTypeData,
  MobileIotdisconnectedCount,
  MobileIotTotalCount,
  FleetStatusCount,
  CityCategory,
  MobileDayWise,
  MobileTrackerDetail,
  MapMobileLocation,
  iotMobileLocationResponse,
  threewheelerTrackerTabledata,
  threewheelerloadejourneydata,
  threewheelerLiveLocationdata,
  threewheelerDistanceTabledata,
  threewheelerAllondata,
  threewheelerAllondatadownload
} from "../../@Types/DRO/ApiResponses";

export class HyperTrackHome {
  public static api = new HyperTrackHome();
  private instance: AxiosInstance;
  static googleMapsOnDutyRiders() {
    throw new Error("Method not implemented.");
  }
  constructor() {
    this.instance = axios.create({
      baseURL: "https://data.bcykal.com/tracker/",
    });
  }
  formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to be 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  formatDateYear = (date: Date | null): string | null => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to be 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  iotNumber(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<IotNumber> {
    let apiUrl = "iot/tracker/status/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<IotNumber>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  googleMapsIotLocation(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MapIotLocation> {
    let apiUrl = "iot/tracker/status_wise/map";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MapIotLocation>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  googleMapsMobileLocation(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MapMobileLocation> {
    let apiUrl = "mobile/map/locations";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MapMobileLocation>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  iotDisconnections(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<IotDisconnections> {
    let apiUrl = "iot/tracker/day_wise/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<IotDisconnections>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  iotType(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<IotType> {
    let apiUrl = "iot/tracker/iot_type/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<IotType>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  exporttrackerDetail(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null,
    pageSize: number | null,
    pageNumber: number | null
  ): Promise<TrackerDetail> {
    let apiUrl = "iot/tracker/details";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<TrackerDetail>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
          page_size: pageSize,
          page_number: pageNumber,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  mobileexporttrackerDetail(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null,
    pageSize: number | null,
    pageNumber: number | null
  ): Promise<MobileTrackerDetail> {
    let apiUrl = "mobile/details";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileTrackerDetail>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
          page_size: pageSize,
          page_number: pageNumber,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  trackerDetail(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null,
    pageSize: number | null,
    pageNumber: number | null,
    searchParam: string | null


  ): Promise<TrackerDetail> {
    let apiUrl = "iot/tracker/details";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';
    
   
    return this.instance
    
      .get<TrackerDetail>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
          page_size: pageSize,
          page_number: pageNumber,
          qrcode:searchParam
        },
      })
      .then((e) => {
        return e.data.data;
      });
    
  }
  mobiletrackerDetail(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null,
    pageSize: number | null,
    pageNumber: number | null,
    searchParam: string | null


  ): Promise<MobileTrackerDetail> {
    let apiUrl = "mobile/details";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';
    
   
    return this.instance
    
      .get<MobileTrackerDetail>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
          page_size: pageSize,
          page_number: pageNumber,
          qrcode:searchParam
        },
      })
      .then((e) => {
        return e.data.data;
      });
    
  }
  hubwisedata(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] |null
  ): Promise<HubWiseData> {
    let apiUrl = "iot/tracker/hubwise/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
    .get<HubWiseData>(apiUrl + '?' + daysParams, {
      headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  vehicleStatusBifurcation(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<VehicleStatusWise> {
    let apiUrl = "iot/tracker/vehicle_status/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';
    return this.instance
      .get<VehicleStatusWise>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  clientTypeMapping(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MerchantCategory> {
    let apiUrl = "iot/tracker/merchant_category/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MerchantCategory>(apiUrl + '?' + daysParams, {

        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  cityWiseMapping(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<CityData> {
    let apiUrl = "/iot/tracker/city_wise/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<CityData>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  fleetTypeBifurcation(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<FleetTypeData> {
    let apiUrl = "/iot/tracker/fleet_type/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<FleetTypeData>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  mobilefleetTypeBifurcation(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MobileFleetTypeData> {
    let apiUrl = "mobile/fleet_type/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileFleetTypeData>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  lastLocationYearWise(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<LastLocationYear> {
    let apiUrl = "/iot/tracker/last_location_update/all";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<LastLocationYear>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  lastLocationMonthWise(
    year: string | null,
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<LastLocationMonth> {
    let apiUrl = "/iot/tracker/last_location_update/particular_year";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<LastLocationMonth>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          year: year,
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  lastLocationVsStartTime(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<LastLocationVsStartTime> {
    let apiUrl = "/iot/tracker/last_location/ride_start/comparison";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<LastLocationVsStartTime>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  hotspotsHypertrack(
    startDate: Date | null,
    endDate: Date | null,
    page: number,
    merchant: string | null,
    city: string | null,
    searchParam: string | null
  ): Promise<hotspotListResponse> {
    let apiUrl = "list/v2";
    if (searchParam) {
      apiUrl += `?${searchParam}`;
      return this.instance
        .get<hotspotListResponse>(apiUrl, {
          headers: {},
        })
        .then((e) => {
          return e.data;
        });
    }
    // Only encode the merchant name if it is provided
    else {
      const encodedMerchant = merchant ? merchant : null;

      return this.instance
        .get<hotspotListResponse>(apiUrl, {
          headers: {},
          params: {
            city: city,
            merchant: encodedMerchant,
            page: page,
          },
        })
        .then((e) => {
          return e.data;
        });
    }
  }
  threewheelerTrackerTabledata(
    startDate:string | null,
    endDate:string| null,
    page:number| null,
    searchParam: string | null,
   
  ): Promise<threewheelerTrackerTabledata | any> {
    
    let apiUrl = "loader/get/voltage_data";
    
    if (searchParam) {
      apiUrl += `?chasis_number=${searchParam}`;
      return this.instance
        .get<threewheelerTrackerTabledata>(apiUrl, {
          headers: {},
          params: {
            start_date:startDate,
            end_date:endDate,
            page: page,
          },
        })
        .then((e) => {
          return e.data;
        });
    }
    
    // If searchParam is null, return undefined or handle accordingly
    return Promise.resolve(undefined); 
  }
  threewheelerDistanceTabledata(
    searchParam: string | null,
   
  ): Promise<threewheelerDistanceTabledata | any> {
    
    let apiUrl = "loader/get/distance_data";
    
    if (searchParam) {
      apiUrl += `?chasis_number=${searchParam}`;
      return this.instance
        .get<threewheelerDistanceTabledata>(apiUrl, {
          headers: {},
        })
        .then((e) => {
          return e.data;
        });
    }
    
    // If searchParam is null, return undefined or handle accordingly
    return Promise.resolve(undefined); 
  }
  threewheelerRiderJourney(
    startDate:string | null,
    endDate:string| null,
    searchParam: string | null,
   
  ): Promise<threewheelerloadejourneydata | any> {
    
    let apiUrl = "loader/get/journey_data";
    
    if (searchParam) {
      apiUrl += `?chasis_number=${searchParam}`;
      return this.instance
        .get<threewheelerloadejourneydata>(apiUrl, {
          headers: {},
          params: {
            start_date:startDate,
            end_date:endDate
          },
        })
        .then((e) => {
          return e.data;
        });
    }
    
    // If searchParam is null, return undefined or handle accordingly
    return Promise.resolve(undefined); 
  }
  threewheelerRiderLiveLocation(
    startDate:string | null,
    endDate:string| null,
    searchParam: string | null,
   
  ): Promise<threewheelerLiveLocationdata | any> {
    
    let apiUrl = "loader/get/last_location_data";
    
    if (searchParam) {
      apiUrl += `?chasis_number=${searchParam}`;
      return this.instance
        .get<threewheelerLiveLocationdata>(apiUrl, {
          headers: {},
          params: {
            start_date:startDate,
            end_date:endDate,
          },
        })
        .then((e) => {
          return e.data;
        });
    }
    
    // If searchParam is null, return undefined or handle accordingly
    return Promise.resolve(undefined); 
  }
  threewheelerTabledata(
    startDate: Date | null,
    endDate: Date | null,
    page: number,
    merchant: string | null,
    city: string | null,
    searchParam: string | null
  ): Promise<threewheelerTabledata> {
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "loader/get/details";
    if (searchParam) {
      apiUrl += `?${searchParam}`;
      return this.instance
        .get<threewheelerTabledata>(apiUrl, {
          headers: {},
        })
        .then((e) => {
          return e.data;
        });
    }
    // Only encode the merchant name if it is provided
    else {
      const encodedMerchant = merchant ? merchant : null;

      return this.instance
        .get<threewheelerTabledata>(apiUrl, {
          headers: {},
          params: {
            city: city,
            merchant: encodedMerchant,
            end_date: formattedEndDate,
          },
        })
        .then((e) => {
          return e.data;
        });
    }
  }
  threewheelerAllTabledata(
    startDate: Date | null,
    endDate: Date | null,
    searchParam: string | null,
    page:number | null,
   
  ): Promise<threewheelerAllondata | any> {
    const formattedStartDate = startDate ? this.formatDateYear(startDate) : null;
    const formattedEndDate = endDate ? this.formatDateYear(endDate) : null;
    let apiUrl = "loader/get/all_vehicle_distance_data?client=BAJAJ";
    
    if (searchParam) {
      apiUrl += `&search=${searchParam}`;
      return this.instance
        .get<threewheelerAllondata>(apiUrl, {
          headers: {},
          params: {
            start_date:formattedStartDate,
            end_date:formattedEndDate,
            page:page
          },
        })
        .then((e) => {
          return e.data;
        });
    }
    
    else {
      
      return this.instance
      .get<threewheelerAllondata>(apiUrl, {
        headers: {},
        params: {
          start_date:formattedStartDate,
          end_date:formattedEndDate,
          page:page
        },
      })
      .then((e) => {
        return e.data;
      });
    }
  }
  threewheelerAllTabledatadownload(): Promise<threewheelerAllondatadownload | any> {
    return this.instance
      .get<threewheelerAllondatadownload>("loader/get/all_vehicle_distance_data?client=BAJAJ", {})
      .then((e) => {
        return e.data;
      });
  }
  allhotspotListResponse(): Promise<allhotspotListResponse> {
    return this.instance
      .get<allhotspotListResponse>("list/v2", {})
      .then((e) => {
        return e.data;
      });
  }
  eautohotspotListResponse(): Promise<eautohotspotListResponse> {
    return this.instance
      .get<eautohotspotListResponse>("loader_data", {})
      .then((e) => {
        return e.data;
      });
  }
  googleMapsOnDutyRidersHyperTrack(
    startDate: Date | null,
    endDate: Date | null,
    merchant: string | null,
    city: string | null
  ): Promise<MapOnRiderResponseHyperTrack[]> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    const encodedMerchant = merchant ? merchant : null;
    const encodedCity = city ? city : null;
    return this.instance
      .get<MapOnRiderResponseHyperTrack[]>("/riders_map", {
        params: {
          city: encodedCity,
          merchant: encodedMerchant,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })

      .then((e) => {
        return e.data;
      });
  }
  merchantListHyperTrack(): Promise<merchantListResponse> {
    return this.instance
      .get<merchantListResponse>("/merchant/list", {})
      .then((e) => {
        return e.data;
      });
  }
  cityListHyperTrack(): Promise<cityListResponse> {
    return this.instance.get<cityListResponse>("/city/list", {}).then((e) => {
      return e.data;
    });
  }
  summaryRider(
    startDate: Date | null,
    endDate: Date | null,
    merchant: string | null,
    city: string | null
  ): Promise<riderListSummaryResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    const encodedMerchant = merchant ? merchant : null;
    const encodedCity = city ? city : null;

    return this.instance
      .get<riderListSummaryResponse>("/summary", {
        params: {
          city: encodedCity,
          merchant: encodedMerchant,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  threewheelersummaryData(
    startDate: Date | null,
    endDate: Date | null,
    merchant: string | null,
    city: string | null
  ): Promise<threewheelerSummaryResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    const encodedMerchant = merchant ? merchant : null;
    const encodedCity = city ? city : null;

    return this.instance
      .get<threewheelerSummaryResponse>("/loader/get/cards_info", {
        params: {
          city: encodedCity,
          merchant: encodedMerchant,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  hotspotArea(
    startDate: Date | null,
    endDate: Date | null,
    merchant: string | null,
    city: string | null
  ): Promise<hotspotAreaResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    const encodedMerchant = merchant ? merchant : null;
    const encodedCity = city ? city : null;

    return this.instance
      .get<hotspotAreaResponse>("/hotspot_area", {
        params: {
          city: encodedCity,
          merchant: encodedMerchant,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  workHomeLocation(
    cycleId: number | null,
    endDate: Date | null
  ): Promise<workHomeLocationResponse> {
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "work_home/";
    if (cycleId) {
      apiUrl += `${cycleId}/${formattedEndDate}`;
    }
    return this.instance.get<workHomeLocationResponse>(apiUrl, {}).then((e) => {
      return e.data;
    });
  }
  iotMobileLocation(
    qrCode: string | null,
  ): Promise<iotMobileLocationResponse> {
    let apiUrl = "mobile/map/last_iot_mobile_location?qr_code=";
    if (qrCode) {
      apiUrl += `${qrCode}`;
    }
    return this.instance.get<iotMobileLocationResponse>(apiUrl, {}).then((e) => {
      return e.data;
    });
  }
  mobileconnected(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MobileIotCount> {
    let apiUrl = "mobile/connected/iot/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileIotCount>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  mobiledisconnected(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MobileIotdisconnectedCount> {
    let apiUrl = "mobile/disconnected/iot/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileIotdisconnectedCount>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  mobiletotal(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MobileIotTotalCount> {
    let apiUrl = "mobile/iot/total";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileIotTotalCount>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  fleetStatus(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<FleetStatusCount> {
    let apiUrl = "mobile/vehicle_status/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<FleetStatusCount>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
  cityTypeMapping(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<CityCategory> {
    let apiUrl = "mobile/city_wise/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<CityCategory>(apiUrl + '?' + daysParams, {

        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue
        },
      })
      .then((e) => {
        return {
          data: e.data.data,
        };
      });
  }
  
  mobileiotDayWise(
    selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
  ): Promise<MobileDayWise> {
    let apiUrl = "mobile/day_wise/counts";
    const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

    return this.instance
      .get<MobileDayWise>(apiUrl + '?' + daysParams, {
        headers: {},
        params: {
          city: selectedCityValue,
          merchant: selectedClientValue,
          vehicle_type: selectedVehicleTypeValue,
          connection_status: selectedDisconnectionValue,
        },
      })
      .then((e) => {
        return e.data.data;
      });
  }
}
