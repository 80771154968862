import React from "react";
import Header from "../components/Layouts/AppHeader/AppHeader";
import SignIn from "../components/Layouts/SignIn";
import ForgotPassword from "../components/Layouts/ForgotPassword";
import { Routes } from "../states/States";
import Homepage from "../views/home";
import DataTable from "../components/Layouts/RiderDetailsTracking/RiderDetailsTracking";
import CreateStore from "../components/Layouts/CreateStore/CreateStore";
import { element } from "prop-types";
//import { CreateNewHub } from "../components/Layouts/CreateHub/CreateNewHub";
import TeamAndSpocs from "../components/Layouts/TeamAndSpocs/TeamAndSpocs";
import RiderKyc from "../components/Layouts/RiderKyc/RiderKyc";
import AlluserReport from "../components/Layouts/TlCmTable/TlCmTable";
import AllotedUserReport from "../components/Layouts/AllotedUserReport";
import PunchInTable from "../components/Layouts/PunchInTable";
import NewRiderDemand from "../components/Layouts/NewRiderDemand";

import FieldVisitReport from "../components/Layouts/FieldVisitReport";
import CreateUser from "../components/Layouts/CreateUser";
import ChangeTl from "../components/Layouts/ChangeTl";
import ManageStore from "../components/Layouts/ManageStore";
import RiderDeatilKyc from "../components/Layouts/RiderDetailsKyc/RiderDetailsKyc";
import NewFleetDemand from "../components/Layouts/NewFleetDemand/NewFleetDemand";
import NewFleetDemandForm from "../components/Layouts/NewFleetDemand/NewFleetDemandForm";
import TicketAndGrievances from "../components/Layouts/TicketsAndGrievances/TicketsAndGrievances";
import Profile from "../components/Layouts/Profile/Profile";
import LiveTracking from "../components/Layouts/liveTrackingRider/liveTrackingRider";
import HyperTrackHomepage from "../components/Layouts/HyperTrack/HomePage";
import ThreewheelerTracker from "../components/Layouts/HyperTrack/3wTracker";
import DistanceReportList from "../components/Layouts/HyperTrack/DistanceReport";
import DistanceReportYearWiseList from "../components/Layouts/HyperTrack/DistanceReportYearWise";
import AdminSignIn from "../components/Layouts/AdminSignIn";
import RiderTrack from "../components/Layouts/HyperTrack/ridertracknew/Ridertrack";
import RiderTrackThreeWheeler from "../components/Layouts/HyperTrack/ridertrackthreewheeler/Ridertrack";
import RiderWholeJourney from "../components/Layouts/HyperTrack/riderwholejurney/googleMapLive";
import Threewheeler from "../components/Layouts/HyperTrack/3wheeler";
import AllThreewheeler from "../components/Layouts/HyperTrack/all3wheeler";

import IotTracker from "../components/Layouts/IotTracker";
import MobileDataTracker from "../components/Layouts/HyperTrack/mobiledatatracker/googleMapLive";
import MobileTracker from "../components/Layouts/MobileTracker";
import MobileTrackerMap from "../components/Layouts/HyperTrack/mobiletrackermap/googleMapLive";
import { Navigate } from "react-router";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("access_token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};


const CommonRoutes: Array<Routes> = [
  {
    path: "/header",
    element: (<RequireAuth><Header /></RequireAuth>),
  },
  {
    path: "/Dashboard",
    element: (<RequireAuth><Homepage /></RequireAuth>),
  },

  // {
  //   path: "/create-hub",
  //   element: <CreateNewHub />,
  // },

  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/teamAndSpocs",
    element: <TeamAndSpocs />,
  },
  {
    path: "/riderKyc",
    element: (<RequireAuth><RiderKyc /></RequireAuth>),
  },
  {
    path: "/alluserReport",
    element: (<RequireAuth><AlluserReport /></RequireAuth>),
  },
  {
    path: "/punchInTable",
    element: (<RequireAuth><PunchInTable /></RequireAuth>),
  },
  {
    path: "/newRiderDemand",
    element: (<RequireAuth><NewRiderDemand /></RequireAuth>),
  },
  {
    path: "/fieldVisitReport",
    element: (<RequireAuth><FieldVisitReport /></RequireAuth>),
  },
  {
    path: "/allottedUserReport",
    element: (<RequireAuth><AllotedUserReport /></RequireAuth>),
  },
  {
    path: "/riderdeatilkyc",
    element: (<RequireAuth><RiderDeatilKyc /></RequireAuth>),
  },
  {
    path: "/changetl",
    element: (<RequireAuth><ChangeTl /></RequireAuth>),
  },
  {
    path: "/managestore",
    element: (<RequireAuth><ManageStore /></RequireAuth>),
  },
  {
    path: "/createuser",
    element: (<RequireAuth><CreateUser /></RequireAuth>),
  },
  {
    path: "/riderDetailsTracking",
    element: <DataTable />,
  },
  {
    path: "/newFleetDemand",
    element: <NewFleetDemand />,
  },
  {
    path: "/createStore",
    element: <CreateStore />,
  },
  {
    path: "/newFleetDemandForm",
    element: <NewFleetDemandForm />,
  },
  {
    path: "/ticketAndGrievances",
    element: <TicketAndGrievances />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/liveTrackingRider",
    element: <LiveTracking />,
  },
  {
    path: "/zypptracker",
    element: <HyperTrackHomepage />,
  },
  {
    path: "/zypptracker/distanceReport",
    element: <DistanceReportList />,
  },
  {
    path: "/zypptracker/distanceReportYearWise",
    element: <DistanceReportYearWiseList />,
  },
  {
    path: "/zypptracker/threewheelertracker",
    element: <ThreewheelerTracker />,
  },
  {
    path: "/zypptracker/threewheelerlist",
    element: <AllThreewheeler />,
  },
  {
    path: "/admin",
    element: <AdminSignIn />,
  },
  {
    path: "/zypptracker/ridertrack",
    element: <RiderTrack />,
  },
  {
    path: "/zypptracker/riderwholejourney",
    element: <RiderWholeJourney />,
  },
  {
    path: "/zypptracker/threewheeler",
    element: <Threewheeler />,
  },
  {
    path: "/zypptracker/ridertrackthreewheeler",
    element: <RiderTrackThreeWheeler />,
  },
  {
    path: "/zypptracker/iottracker",
    element: <IotTracker />,
  },
  {
    path: "/zypptracker/mobiledatatracker",
    element: <MobileDataTracker />,
  },
  {
    path: "/zypptracker/mobiletracker",
    element: <MobileTracker />,
  },
  {
    path: "/zypptracker/mobiletrackermap",
    element: <MobileTrackerMap />,
  },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  // {
  //   path: "", // test route
  //   element: <Header />,
  //   children: [
  //     ...CommonRoutes,
  //     {
  //       path: "*", // test route
  //       element: <div>NOT FOUND</div>,
  //     },
  //   ],
  // },
];
