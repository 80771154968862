import React, {  useEffect, useRef, useState } from "react";
import {
  Toolbar,
  Typography,
  FormControl,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

import { RiderKycApi } from "../../../API/RiderKycApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";

export default function RiderDetailKyc() {
  const navigate = useNavigate();
  const [errorVerify, setErrorVerify] = useState<string>("");
  
  const [userName, setUserName] = useState<any>("");
  const [employeeId, setEmployeeId] = useState<any>("");
  const [userMobileNumber, setUserMobileNumber] = useState<any>("");
  const [userEmail, setUserEmail] = useState<any>("");
  const [selectedCity, setSelectedCity] = useState<any>("");
  const [userPassword, setUserPassword] = useState<any>("");
  const [userLocation, setUserLocation] = useState<any>("");
  const [selectedRole, setSelectedRole] = useState<any>("");
  const [selectedUserMapped, setSelectedUserMapped] = useState<any>("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [allCityList, setAllCityList] =  useState<any[]>([]);;
  const [userMapped, setUserMapped] =  useState<any[]>([]);;
  const token = localStorage.getItem("access_token");
  const formRef = useRef<HTMLFormElement | null>(null);

  

  useEffect(() => {
    RiderKycApi.api?.AllCityList()
    .then((response) => {
     
      if (response.data.length > 0) {
        setAllCityList(response.data);
      } else {
        setAllCityList([]);
      }
    })
    .catch((error) => {
      console.error("API call error: ", error);
    });
    
    RiderKycApi.api?.UserMapped(selectedRole)
    .then((response) => {
     
      if (response.data.length > 0) {
        setUserMapped(response.data);
      } else {
        setUserMapped([]);
        setErrorVerify(response.message || "Verification failed.");
      }
    })
    .catch((error) => {
      console.error("API call error: ", error);
    });

   
  }, [selectedRole]);

  const resetFormFields = () => {
    setUserName("");
    setEmployeeId("");
    setUserMobileNumber("");
    setUserEmail("");
    setSelectedCity("");
    setUserPassword("");
    setUserLocation("");
    setSelectedRole("");
    setSelectedUserMapped("");
  };
  
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    // Prepare the request body
    const requestBody = {
      name: userName,
      employeeId: employeeId,
      mobile: userMobileNumber,
      email: userEmail,
      cityCode: selectedCity,
      password: userPassword,
      location: userLocation,
      authority: selectedRole,
      mappedWith: selectedUserMapped,
    };
    try {
      const response = await fetch(
        "https://beta.bcykal.com/mobycy/clientDash/add/executive",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        setShowSuccessSnackbar(true);
        setTimeout(() => {
          resetFormFields(); // Reset the form fields after successful submission
          window.location.reload();
      
        }, 1000); // 3 seconds delay
       


      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Error creating store");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  
  const isFormValid = () => {
    return (
      userName &&
      employeeId &&
      userMobileNumber &&
      userEmail &&
      selectedCity &&
      userPassword &&
      userLocation &&
      selectedRole &&
      selectedUserMapped
    );
  }


  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8">
        <div style={{ height: "40px", width: "100%" }}>
          <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <a onClick={() => navigate("/alluserReport")} className="cursor-pointer">
                <ArrowBackIcon className="mr-4 mb-1 ml-7" />
              </a>
              <b>Create User</b>
            </Typography>

            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                marginRight: "48px",
              }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
              Create User
            </Typography>
          </Toolbar>
        </div>
        <div className="bg-[#fff] p-8 rounded-lg pb-4 mb-4">
        <form ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={5} className="w-full mt-4 flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Name</p>
                <TextField
                  id="outlined-basic"
                  label="User Name"
                  variant="outlined"
                  className="w-full"
                  type="text" // Set input type to number
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Employee ID</p>
                <TextField
                  id="outlined-basic"
                  label="Employee ID"
                  variant="outlined"
                  className="w-full"
                  type="text" // Set input type to number
                  onChange={(e) => {
                    setEmployeeId(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Mobile Number</p>
                <TextField
                  id="outlined-basic"
                  label="Mobile Number"
                  variant="outlined"
                  type="number" // Set input type to number
                  className="w-full"
                  onChange={(e) => {
                    setUserMobileNumber(e.target.value);
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <hr className="my-7" />
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Email<sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Email"
                  variant="outlined"
                  className="w-full"
                  type="email" // Set input type to number
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-full mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  User City <sup style={{ color: 'red' }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="city"
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                    }}
                    style={{
                      border: 1,
                      borderColor: "#c0baba",
                      borderStyle: "solid",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}

                  >
                    <option value="" selected>Select Area</option> {/* Default option */}

                    {allCityList.map((item, index) => (
                      <option key={index} value={item.cityId}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Password <sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Password"
                  variant="outlined"
                  className="w-full"
                  type="password" // Set input type to number
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                 
                />
              </div>
            </Grid>
          </Grid>
          <hr className="my-7" />
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item xs={4}>
              <div>
                <p className="text-left mb-4 text-sm font-bold">User Location <sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Location"
                  variant="outlined"
                  className="w-full"
                  type="text" // Set input type to number
                  onChange={(e) => {
                    setUserLocation(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-full mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  User Role <sup style={{ color: 'red' }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="city"
                    onChange={(e) => {
                      setSelectedRole(e.target.value);
                      setErrorVerify("")
                    }}
                    style={{
                      border: 1,
                      borderColor: "#c0baba",
                      borderStyle: "solid",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}
                   
                  >
                   <option value="" selected>Select Role </option>
              <option value={"0"}>Team Leader</option>
              <option value={"1"}>Cluster Manager</option>
              <option value={"2"}>City Head</option>
              <option value={"3"}>Opertaion Head</option>
              <option value={"4"}>Business Head</option>
                  </select>
                </FormControl>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-full mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  User Mapped with <sup style={{ color: 'red' }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="city"
                    onChange={(e) => {
                      setSelectedUserMapped(e.target.value);
                    }}
                    style={{
                      border: 1,
                      borderColor: "#c0baba",
                      borderStyle: "solid",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}

                  >
                    <option value="" selected>Select Area</option> {/* Default option */}

                    {userMapped.map((item, index) => (
                      <option key={index} value={item.userId}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errorVerify && <p style={{ color: "red", textAlign:"left", fontSize:'14px' }}>{errorVerify}</p>}

                </FormControl>
              </div>
            </Grid>

           
          </Grid>
        
              <div className="text-right w-full ">
              <button
                type="submit"
                disabled={!isFormValid()} // Disable if form is not valid
                className={`px-12 py-4 mt-8  w-60	 text-white rounded-lg bg-[#FE5B44] hover:opacity-80 transition-opacity duration-300 ease-in-out ${
                  !isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Submit
              </button>
              
               
              </div>
          </form>
          <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="success">
            USER CREATED SUCCESSFULLY!
          </Alert>
        </Snackbar>
        <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            {SnackbarMessage?.replace(/_/g, " ")}
          </Alert>
        </Snackbar>

        </div>
      </div>
    </>
  );
}
