import React, { useEffect, useState } from "react";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  LinearProgress,
  Link,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CSVLink } from "react-csv";
import VisibilityIcon from '@mui/icons-material/Visibility';


const HyperTrackHomepage = () => {

  const allautocsvHeaders = [
    { label: "S.No", key: "index" },
    { label: "Chassis Number", key: "clientSideId" },
    { label: "Date", key: "date" },
    { label: "First soc", key: "first_soc_reading" },
    { label: "Last soc", key: "last_soc_reading" },
    { label: "First odo", key: "first_odo_reading" },
    { label: "Last odo", key: "last_odo_reading" },
    { label: "Distance", key: "distance" }
  ];
  const allautocsvHeadersdownload = [
    { label: "S.No", key: "index" },
    { label: "Chassis Number", key: "clientSideId" },
    { label: "Date", key: "date" },
    { label: "First soc", key: "first_soc_reading" },
    { label: "Last soc", key: "last_soc_reading" },
    { label: "First odo", key: "first_odo_reading" },
    { label: "Last odo", key: "last_odo_reading" },
    { label: "Distance", key: "distance" }
  ];
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
    textAlign: "center" as const, // Explicitly specify the literal type

  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
    textAlign: "center" as const, // Explicitly specify the literal type

  };
  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };

  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [allListData, setAllListData] = useState<any>();
  const [allListDatadownload, setAllListDatadownload] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(calculateRelativeDate(1));
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(0));
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();



  useEffect(() => {
    setLoading(true);
    HyperTrackHome.api
      .threewheelerAllTabledata(startDate, endDate, searchVal,  page + 1,)
      .then((e) => {
        setShowSnackbar(false);
        setLoading(false);
        if (e?.data?.distance_data && e.data.distance_data.length > 0) {
          setAllListData(e.data.distance_data);
          setTotalCount(e.data.records)
        } else {
          setAllListData([]); // Clear the table data on error
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching data:', error);
        setAllListData([]); // Clear the table data on error

        setShowSnackbar(true); // Optionally show the snackbar on error
      });
  }, [endDate, startDate, searchVal, page]);


  useEffect(() => {

    HyperTrackHome.api.threewheelerAllTabledatadownload().then((e) => {
      if (e?.data?.distance_data && e.data.distance_data.length > 0) {
        setAllListDatadownload(e?.data?.distance_data);
      } 
    });
 
  }, []);

console.log('setall data', allListDatadownload)

  const handleAdd = (uid: {
    clientSideId: any;
    date:any;
    row: { original: { clientSideId: any; date:any; } };
  }) => {
    //   setUserId(uid);
    navigate("/zypptracker/threewheelertracker", {
      state: { chassisNum: uid.clientSideId, date:uid.date },
    });
  };
  const pageRange = 5;

  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft bg-[#f2f2f2] px-12">
      <div className="flex justify-between w-full mt-4">
        <div className="flex">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            3W List
          </Typography>
        </div>

        <div className="flex">
          <div className="flex items-center ">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={([start, end]) => {
                setStartDate(start); // Update start date
                setEndDate(end);     // Update end date
              }}
              maxDaysAdd={0}
              minDaysSub={92}
              defaultStartDate={1}
              defaultEndDate={0}
            />
          </div>
          <div className="flex ml-4">

            <CSVLink
              data={allListData || []}
              headers={allautocsvHeaders}
              filename={`3w_all_data_datewise.csv`}
              style={{ marginRight: '10px' }}
            >
              <Button
                style={{
                  backgroundColor: "#00CD5B",
                  padding: "8px 8px",
                  color: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                  textTransform: "none",
                }}
              >
                3w Data DateWaise Download CSV
              </Button>
            </CSVLink>

            <CSVLink
              data={allListDatadownload || []}
              headers={allautocsvHeadersdownload}
              filename={`3w_all_data.csv`}
              style={{ marginRight: '10px' }}
            >
              <Button
                style={{
                  backgroundColor: "#00CD5B",
                  padding: "8px 8px",
                  color: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                  textTransform: "none",
                }}
              >
                3w All Data Download CSV
              </Button>
            </CSVLink>

          </div>
        </div>

      </div>


      <>
        <div className="w-full">

          <div className="border-0 rounded-3xl pt-8 my-4 bg-white">
            <div className="flex justify-between mb-4 pl-12 pr-8">
              <div className="flex">
                <TextField
                  placeholder="Search Chassis Number"
                  variant="outlined"
                  onChange={(search) => setSearchVal(search.target.value)}
                  sx={{ width: 330 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LinearProgress
                className="pl-6 pr-6"
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                  width: "100%",
                }}
              />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.3 }}>
                        S.No
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.5 }}>
                        Chassis Number
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.8 }}>
                        Date
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.9 }}>
                        First SOC (%)
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.9 }}>
                        Last SOC (%)
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.2 }}>
                        First ODO (Meter)
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.25 }}>
                        Last ODO (Meter)
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                        Distance (KM)
                      </TableCell>

                      <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allListData?.map((row: any) => (
                      <TableRow sx={{ display: "flex" }} key={row.index}>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 0.3 }}>
                          {row.index}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 1.5 }}>
                          {row.clientSideId}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 0.8 }}>
                          {row.date}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 0.9 }}>
                          {row.first_soc_reading}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 0.9 }}>
                          {row.last_soc_reading}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 1.2 }} >
                          {row.first_odo_reading}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 1.25 }}>
                          {row.last_odo_reading}
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                          {row.distance}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                          <Stack direction="row" spacing={0}>
                            <Link
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleAdd(row);
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#00CD5B" }}
                              >
                                <VisibilityIcon />
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
         
          <div className="pl-2 pt-4 pb-2 pr-16 mr-2">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                >
                  {"<"}
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= pageCount - 1}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginLeft: "6px",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default HyperTrackHomepage;
