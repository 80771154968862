import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import { addDays, subDays } from "date-fns";
import "./AppDatePicker.css";

registerLocale("en-GB", enGB);

interface RangeDatePickerProps {
  onChange: (dates: [Date | null, Date | null]) => void;
  minDaysSub: number;
  maxDaysAdd: number;
  defaultStartDate?: number | null; // Optional or nullable
  defaultEndDate?: number | null;   // Optional or nullable
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
  onChange,
  minDaysSub,
  maxDaysAdd,
  defaultStartDate = null,
  defaultEndDate = null,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    defaultStartDate !== null ? subDays(new Date(), defaultStartDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    defaultEndDate !== null ? addDays(new Date(), defaultEndDate) : null
  );

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    onChange(dates);
  };

  return (
    <DatePicker
      selectsRange
      dateFormat="dd/MM/yyyy"
      startDate={startDate}
      endDate={endDate}
      onChange={handleDateChange}
      isClearable
      minDate={subDays(new Date(), minDaysSub)}
      maxDate={addDays(new Date(), maxDaysAdd)}
      locale="en-GB"
      className="mr-1 px-4 py-1 border rounded"
    />
  );
};

export default RangeDatePicker;
