import React, { useState } from "react";
import WrappedMap from "./gmap";
import moment from "moment";
import { Box, Button, Grid, InputAdornment, LinearProgress, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import RangeDatePicker from "../../../UI/AppDatePickerClick/AppDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router";

export interface Path {
  work_latitude: number;
  work_longitude: number;
  home_longitude: number;
  home_latitude: number;
  time_stamp: any;
  home_long: number;
  home_lat: number;
  rideId: number;
  cycleId: number;
  latitude: number;
  longitude: number;
  createdTime: string;
  Distance: number;
  Location: number;
  work_long: number;
  work_lat: number;
  Date: string;
  Start_time: string;
  End_time: string;
  time_diff: number;
  date: string;
}

function App(props: any) {

  const [mapdata, setMapData] = useState<Path[]>([]);
  
  const [workhomelocation, setWorkHomeLocation] = useState<any | null>(null);
  const [loaderpassword, setLoaderpassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [workLat, setWorkLat] = useState<any | null>(null);
  const [homeLat, setHomeLat] = useState<any | null>(null);
  const [workLng, setWorkLng] = useState<any | null>(null);
  const [homeLng, setHomeLng] = useState<any | null>(null);
  const [currentLat, setCurrentLat] = useState<any | null>(null);
  const [currentLng, setCurrentLng] = useState<any | null>(null);
  const location = useLocation();

  console.log('propsid', location.state);
const QrCode = location.state.qr_code

console.log('QrCode---', QrCode)

  useEffect(() => {
    setLoaderpassword(true);

    // Convert start and end dates to the desired format (assuming YYYY-MM-DD for this example)

    fetch(`https://data.bcykal.com/tracker/mobile/map/home_work_location?qr_code=${QrCode}`)
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Success") {
          setLoaderpassword(false);
          setMapData(data?.data?.journey_data);
          setWorkHomeLocation(data?.data);
          setHomeLng(data?.data?.home_work?.home_longitude);
          setWorkLng(data?.data?.home_work?.work_longitude);
          setHomeLat(data?.data?.home_work?.home_latitude);
          setWorkLat(data?.data?.home_work?.work_latitude);
          setCurrentLat(data?.data?.current_location[0].latitude);
          setCurrentLng(data?.data?.current_location[0].longitude);

          setLoaderpassword(false);
        } else {
          setSnackbarMessage("No data found");
          setLoaderpassword(false);
          setSnackbarOpen(true);
          setMapData([]);
        }
      });

    // Cleanup function if needed
    return () => {
      // Cleanup code here if needed
    };
  }, [homeLat, homeLng, workLat, workLng]); // Empty dependency array ensures the effect runs only once on mount

  

  return (
    <div style={{ height: 600, width: `100%` }}>
      <Grid container>
        {loaderpassword && (
          <Grid item sm={12} lg={12}>
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          </Grid>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={9000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      
      </Grid>
      <div style={{ position: 'relative' }}>
        {mapdata && mapdata.length > 0 ? (
          <div style={{ height: "100%", width: "100%" }}>
            
            <WrappedMap
              paths={mapdata.sort((o1, o2) => o1.Distance - o2.Distance)}
              //workhomelocation={workhomelocation}
              workLat={workLat}
              workLng={workLng}
              homeLat={homeLat}
              homeLng={homeLng}
              currentLat={currentLat}
              currentLng={currentLng}
              qrcode={QrCode}
            />
          </div>
        ) : (
          null
        )}
      </div>
    </div>
  );
}

export default App;
