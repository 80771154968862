import React, { useEffect, useState } from "react";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation } from "react-router-dom";
import moment from "moment";
import WrappedMap from "../../Layouts/HyperTrack/3wWholejurney/gmap";
import WrappedMapLive from "../../Layouts/HyperTrack/3wRiderLiveLoction/gmap";
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import {
  Alert,
  IconButton,
  LinearProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  buttonClasses,
} from "@mui/material";

export interface Path {
  latitude: number;
  longitude: number;
  createdTime: string;

}
const green = {
  50: '#E6F9EF', // Very Light Green
  100: '#B3F1D4', // Light Green
  200: '#80E9BA', // Light Medium Green
  300: '#4DDF9F', // Medium Green
  400: '#26D685', // Medium Dark Green
  500: '#00CD5B', // Base Green
  600: '#00A846', // Dark Green
  700: '#007C34', // Darker Green
  800: '#005422', // Very Dark Green
  900: '#002B10', // Deepest Green
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${green[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.6;
  width: 100%;
  `,
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  background-color: ${green[500]};
  width: fit-content;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};

  `,
);
const HyperTrackHomepage = () => {

  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#3b3b3b",
    font: "Rubik",
  };
  async function getLocality(
    apiKey: string,
    latitude: number,
    longitude: number
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent) {
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }

  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  
  const location = useLocation();
  const defaultDate = location.state?.date;
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [loadingLive, setLoadingLive] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [threewheelerListData, setThreewheelerListData] = useState<any>();
  const [threewheelerDistance, setThreewheelerDistance] = useState<any>();
  const [threewheelerJourneyData, setThreewheelerJourneyData] = useState<any>();
  const [threewheelerLiveLocation, setThreewheelerLiveLocation] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(defaultDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultDate);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [hotspots, setHotspots] = useState<any>();
  const [addressData, setAddressData] = useState<
    Array<{ location: string | null; totalOrders: number }>
  >([]);


  const chassisNum = location.state?.chassisNum


  const fetchHotspotAddresses = async () => {
    const newAddressData: Array<{
      location: string | null;
      totalOrders: number;
    }> = [];

    for (const hotspot of hotspots) {
      try {
        const addresses = await getLocality(
          googleMapApiKey,
          hotspot.Hotspot_Lat,
          hotspot.Hotspot_Long
        );
        const location = addresses !== null ? addresses : "Unknown Address";
        newAddressData.push({ location, totalOrders: hotspot.totalOrders });
      } catch (error) {
        console.error(error);
        newAddressData.push({
          location: "Unknown Address",
          totalOrders: hotspot.totalOrders,
        });
      }
    }

    setAddressData(newAddressData);
  };

  useEffect(() => {
    if (hotspots && hotspots.length > 0) {
      fetchHotspotAddresses();
    }
  }, [hotspots]);
  const pageRange = 5;

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change
   setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
  

  useEffect(() => {
    setLoading(true); // Set loading state while fetching data
    setLoadingMap(true); // Set loading state while fetching data
    setLoadingLive(true); // Set loading state while fetching data


    const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

    HyperTrackHome.api
      .threewheelerDistanceTabledata(chassisNum)
      .then((e) => {
        const distanceData = e.data.distance_data;
        const recordsData = e.data.records;

        // Update state with fetched data
        setThreewheelerDistance(distanceData);
        setTotalCount(recordsData);
        setLoading(false); // Loading is false after data is fetched

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading state in case of error
      });



    HyperTrackHome.api
      .threewheelerTrackerTabledata(formattedStartDate, formattedEndDate, page + 1, chassisNum)
      .then((e) => {
        const voltageData = e.data.loader_voltage_data;
        const recordsData = e.data.records;

        // Update state with fetched data
        setThreewheelerListData(voltageData);
        setTotalCount(recordsData);
        setLoading(false); // Loading is false after data is fetched

        console.log('recordsData:', recordsData); // Log the records data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading state in case of error
      });

    HyperTrackHome.api
      .threewheelerRiderJourney(formattedStartDate, formattedEndDate, chassisNum)
      .then((e) => {
        const voltageData = e.data.loader_journey_data;

        // Update state with fetched data
        setThreewheelerJourneyData(voltageData);
        console.log('data checkkkkkk--', threewheelerJourneyData)
        setLoadingMap(false); // Loading is false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingMap(false); // Stop loading state in case of error
      });
    HyperTrackHome.api
      .threewheelerRiderLiveLocation(formattedStartDate, formattedEndDate, chassisNum)
      .then((e) => {
        const voltageData = e.data.last_location_data;

        // Update state with fetched data
        setThreewheelerLiveLocation(voltageData);
        console.log('voltageData', voltageData)
        setLoadingLive(false); // Loading is false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingLive(false); // Stop loading state in case of error
      });

  }, [endDate, page, chassisNum]);

  
  function formatDate(inputDate: Date) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);

  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };

  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";

  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft bg-[#f2f2f2] px-12">
      <div className="flex justify-between w-full mt-4">
        <div className="flex">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          Three Wheeler Tracker Data from {startDate ? formatDate(startDate) : "N/A"} to {endDate ? formatDate(endDate) : "N/A"}
          </Typography>
        </div>
        <div className="flex  pr-2">


          <div className="flex items-center ">
            <Typography variant="subtitle1" className="font-bold">
              Date Range:
            </Typography>
            <div className="ml-2">
              <RangeDatePicker
                onChange={handleDateChange}
                maxDaysAdd={0}
                minDaysSub={1000}
                defaultStartDate={null}
                defaultEndDate={null}

              />
            </div>
          </div>
        </div>
      </div>


      <div className="flex justify-between w-full mt-5">
        <div className="flex-grow w-1/6	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 font-bold text-base text-[#2B313F]">
              Three wheeler Rider Whole Journey
            </h2>

          </div>
          <div className="flex flex-col items-center justify-center p-3 animate_animated animate__slideInUp">
            {loadingMap ? (
              <LinearProgress
                className="pl-6 pr-6"
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                  width: "100%",
                }}
              />
            ) : (
              !threewheelerJourneyData || threewheelerJourneyData.length === 0 ? (
                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" color={"#ff1744"}>Data Not Found</Typography>
                </Box>
              ) : null
            )}
            <div style={{ width: "100%", height: "5.5rem" }}>
              {threewheelerJourneyData && threewheelerJourneyData.length > 0 ? (

                <WrappedMap
                  paths={threewheelerJourneyData}

                />
              ) : null
              }
            </div>
          </div>
        </div>
        <div className="flex-grow w-1/6	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 mb-3 font-bold text-base text-black">
              Three wheeler Rider Live Location
            </h2>
            <div className="flex">
              <IconButton
                size="small"
                aria-label="Refresh"
                onClick={() => {
                  setLoadingLive(true); // Show loading state
                  const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
                  const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

                  HyperTrackHome.api
                    .threewheelerRiderLiveLocation(formattedStartDate, formattedEndDate, chassisNum)
                    .then((e) => {
                      const voltageData = e.data.last_location_data;
                      setThreewheelerLiveLocation(voltageData);
                      console.log("voltageData", voltageData);
                      setLoadingLive(false); // Set loading to false after data is fetched
                    })
                    .catch((error) => {
                      console.error("Error fetching data:", error);
                      setLoadingLive(false); // Stop loading state in case of error
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-4 pb-3">
            {loadingLive ? (
              <LinearProgress
                className="pl-6 pr-6"
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                  width: "100%",
                }}
              />
            ) : (
              !threewheelerLiveLocation || threewheelerLiveLocation.length === 0 ? (
                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" color={"#ff1744"}>Data Not Found</Typography>
                </Box>
              ) : null
            )}
            <div style={{ width: "100%", height: "5.5rem" }}>
              {threewheelerLiveLocation && threewheelerLiveLocation.length > 0 ? (

                <WrappedMapLive
                  paths={threewheelerLiveLocation}

                />
              ) : null
              }
            </div>
            {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
          </div>
        </div>

      </div>

      <>
        <div className="w-full mb-6">
          {loading ? (
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
                width: "100%",
              }}
            />
          ) : (
            (!threewheelerListData || threewheelerListData.length === 0 ||
              !threewheelerDistance || threewheelerDistance.length === 0) ? (
              <Box sx={{ width: "100%", height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="subtitle1" color={"#ff1744"}>
                  {(!threewheelerListData || threewheelerListData.length === 0) && !threewheelerDistance
                    ? "Vehicle Data and Distance Not Found"
                    : !threewheelerListData || threewheelerListData.length === 0
                      ? "Vehicle Data Not Found"
                      : "Distance Data Not Found"}
                </Typography>
              </Box>
            ) : null
          )}

          <Tabs defaultValue={0}>
            <TabsList>
              <Tab value={0}>Vehicle Data</Tab>
              <Tab value={1}>Distance Travel</Tab>
            </TabsList>
            <TabPanel value={0}> {threewheelerListData && threewheelerListData.length > 0 ? (
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                          S.No
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                          Created Date
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                          Voltage
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                          State Of Charge
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                          State Of Health
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                          Temperature
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {threewheelerListData?.map((row: any) => (
                        <TableRow sx={{ display: "flex" }} key={row.index}>
                          <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                            {row.index}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                            {formatDate(row.createdTime)}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                            {Math.round(row.voltage)}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                            {row.soc}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                            {row.soh}
                          </TableCell>

                          <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                            {row.temperature}
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="pl-2 pt-4 pb-2 pr-16">
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <button
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 0}
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "20px",
                          marginRight: "6px",
                        }}
                      >
                        {"<"}
                      </button>
                      {renderPageNumbers()}
                      <button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page >= pageCount - 1}
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "20px",
                          marginLeft: "6px",
                        }}
                      >
                        {">"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
              : null
            }
            </TabPanel>
            <TabPanel value={1}> {threewheelerDistance && threewheelerDistance.length > 0 ? (
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                          Journey Date
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                          Start Time
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                          End Time
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle} sx={{ flex: 2, textAlign: 'center' }}>
                          Total KM
                        </TableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {threewheelerDistance?.map((row: any) => {
                        const [startDateTime, endDateTime] = row.timestamp.split(" to ");
                        const startTime = startDateTime.split(" ")[1].slice(0, 8); // Extracting only HH:MM:SS
                        const endTime = endDateTime.split(" ")[1].slice(0, 8); // Extracting only HH:MM:SS
                        return (
                          <TableRow sx={{ display: "flex" }} key={row.date}>
                            <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                              {formatDate(row.date)}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                              {startTime}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                              {endTime}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle} sx={{ flex: 2, textAlign: "center" }}>
                              {row.distance}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>


              </div>
            )
              : null
            }
            </TabPanel>

          </Tabs>


        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default HyperTrackHomepage;
