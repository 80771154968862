import React, { useEffect, useRef, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import GoogleMapApi from "../RiderDemandMaps/CreateRiderMap";
import { Place } from "../../../@Types/ComponentsInterface/CreateHubProps";
import { RiderKycApi } from "../../../API/RiderKycApi";

import {
  Alert,
  Button,
  Snackbar,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";

export default function DataTable() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/allottedUserReport");
  };

  const [loading, setLoading] = useState(true);
  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();

  const [nofleet, setNoFleet] = useState<string>("");
  const [selectShop, setSelectShop] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const [selectShopId, setSelectShopId] = useState<string>("");
  const [hubList, sethubList] = useState<any[]>([]);
  const [shopCategory, setShopCategory] = useState<any[]>([]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  
  useEffect(() => {
    RiderKycApi.api?.hubDataList()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          sethubList(response.data);
        } else {
          sethubList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });

    RiderKycApi.api?.shopCategory()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          setShopCategory(response.data);
        } else {
          setShopCategory([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, []);

  const formRef = useRef<HTMLFormElement | null>(null);
  const token = localStorage.getItem("access_token");

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Prepare the request body
    const requestBody = {
      shopId: selectShopId,
      fleetCount: nofleet,
      comment: comments,
      
    };

    try {
      const response = await fetch(
        "https://beta.bcykal.com/mobycy/clientDash/add/riderFormData",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        setShowSuccessSnackbar(true);
        setTimeout(() => {
          setNoFleet("");
          setSelectShop("");
          setComments("");
          //window.location.reload();
        }, 1000);
       
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Error creating store");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  };
  const isFormValid = () => {
    return selectShopId && nofleet && comments;
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };

  return (
    <div style={{ height: "100%", width: "100%" }} className="border-10 bg-gradient-to-r from-slate-100 to-slate-50 pl-6 pr-6">
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, display: "flex", alignItems: "center" }} className="bg-gradient-to-r from-slate-100 to-slate-50">
        <Typography sx={{ flex: "1 1 100%", textAlign: "left" }} variant="h6" id="tableTitle" component="div">
          <a onClick={navigateToHome} className="cursor-pointer text-black">
            <ArrowBackIcon className="mr-4 mb-1" />
          </a>
          <b>New Rider Demand</b>
        </Typography>
        <Typography sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }} component="div">
          <HomeIcon className="mb-1" />
          {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "} New Rider Demand
        </Typography>
      </Toolbar>

      <div className="border-0 rounded-xl m-1 bg-white px-12 py-10">
        <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={5} className="w-full flex justify-between">
        <Grid item md={7}>
           
              <div className="flex justify-between mr-4 mt-6">
                <div className="w-2/4 mr-6">
                  <p className="text-left mb-4 text-sm font-bold">Select Shop</p>
                  <FormControl fullWidth>
                    <select
                      id="merchant"
                      onChange={(e) => {
                        const selectedMerchantId = e.target.value;
                        setSelectShopId(selectedMerchantId);

                        // Find the selected shop in hubList
                        const selectedShop = hubList.find(shop => shop.shopId.toString() === selectedMerchantId);
                        
                        // Update the selected place with the shop's coordinates
                        if (selectedShop) {
                          setSelectedPlace({
                            lat: selectedShop.shopLat,
                            lng: selectedShop.shopLon,
                          } as Place);  // Type assertion to ensure compatibility
                        }
                    
                      }}
                      style={{ border: 1, borderColor: "#c0baba", borderStyle: "solid", padding: 7, borderRadius: 3, height: 56 }}
                    >
                      <option value="" selected>Select New</option>
                      {hubList.map((TL, index) => (
                        <option key={index} value={TL.shopId}>
                          {TL.shopName}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>
                <div className="w-2/4">
                  <p className="text-left mb-4 text-sm font-bold">No. of Fleets</p>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Enter Hub Name"
                    className="text-slate-300"
                    onChange={(e) => {
                      setNoFleet(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between mr-4 mt-6">
                <div className="w-full ">
                  <p className="text-left mt-4 mb-4 text-sm font-bold">Comments</p>
                  <TextField
                    id="outlined-multiline-static"
                    label="Additional Comments"
                    multiline
                    onChange={(e) => {
                      const value = e.target.value;
                      // Only allow numbers up to 10 digits
                      setComments(value);
                    }}
                    rows={4}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="flex justify-end mr-4 mt-6">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isFormValid()} // Disable button if form is invalid
                  sx={{
                    textTransform: "none",
                    bgcolor: "#FF725E",
                    py: 1,
                    px: 10,
                    mt: 2,
                    "&:hover": { bgcolor: "#FF725E95" },
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
            <Grid item md={5}>

            
              <p className="text-left mb-4 text-sm font-bold">Location on Map</p>
              <div style={{ width: "100%", height: "200px" }}>
                <GoogleMapApi
                  latit={selectedPlace?.lat}
                  lngit={selectedPlace?.lng}
                />
              </div>
            </Grid>
            </Grid>
          
        </form>
      </div>

      <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        Request successfully added!
        </Alert>
      </Snackbar>
    </div>
  );
}
