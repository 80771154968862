import React from "react";
import { DashboardFrontCardHyperTrackProps } from "../../../@Types/ComponentsInterface/DashboardFrontCardHyperTrackProps";

const DashboardFrontCardHyperTrack: React.FC<
  DashboardFrontCardHyperTrackProps
> = ({
  fromColorClass,
  toColorClass,
  textFirstLine,
  totalcount,
  imageURL,
  avgcount,
  avgtext,
  shorttext,
}) => {
  const cardStyle = {
    background: `linear-gradient(to right, ${fromColorClass}, ${toColorClass})`,
  };

  return (
    <div
      className={`flex-grow w-2/12 rounded-lg bg-[#fff] p-4 mx-2 shadow h-28  ${fromColorClass} ${toColorClass}`}
      style={cardStyle}
    >
      <h4 className="mb-2">
        <b>{textFirstLine}</b>
      </h4>
      <div className="flex items-center">
        <img src={imageURL} alt="" className="w-8 h-8" />

        <p className="ml-2">
          {totalcount} <span>{shorttext}</span>
        </p>
      </div>
      {avgcount !== 0 ? (
        <div className="flex items-center">
          <p className="mr-1 mt-0">
            <small>{avgtext}</small>
          </p>
          <p className="mt-0">
            <small>
              {avgcount} <span>{shorttext}</span>
            </small>
          </p>
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
};
export default DashboardFrontCardHyperTrack;
